




















































import Vue from 'vue';
import format from '@/services/format';
import log from '@/services/log';

export default Vue.extend({
    name: 'UserDetailsForm',

    data() {
        return {
            transportType: 'flightNumber',
            transportNumber: '',
            comments: '',
            flightNumberRules: [
                (v: string) => {
                    return !!v?.match(/^[a-zA-Z\d\-\s]{0,8}$/) || this.$t('PAGE3.FORM_RULES.CORRECT_FLIGHT_NUMBER');
                },
            ],
            trainNumberRules: [
                (v: string) => {
                    return !!v?.match(/^[a-zA-Z\d\-\s]{0,15}$/) || this.$t('PAGE3.FORM_RULES.CORRECT_TRAIN_NUMBER');
                },
            ],
            commentsRules: [
                (v: string) => {
                    return !!v?.match(/^.{0,512}$/) || this.$t('PAGE3.FORM_RULES.CORRECT_COMMENTS');
                },
            ],
        };
    },

    async mounted() {
        // nop
    },

    watch: {
        transportType: function (value) {
            this.transportType = value;
        },
        transportNumber: function (value) {
            this.transportNumber = value != null ? value : '';
        },
        comments: function (value) {
            this.comments = value != null ? value : '';
        },
    },

    mixins: [{ methods: { ...format } }],

    methods: {
        // public

        isValid() {
            const xx = (this.$refs.formRideDetails as any).validate();
            log.debug(`RideDetailsForm.isValid => ${xx}`);
            return xx;
        },

        getValues() {
            return {
                ...(this.comments ? { comments: this.comments } : undefined),
                ...(this.transportNumber ? { [this.transportType]: this.transportNumber } : undefined),
            };
        },
    },
});
