














import Vue from 'vue';
import format from '@/services/format';

export default Vue.extend({
    name: 'Tag',

    props: ['word'],

    mixins: [{ methods: { ...format } }],
});
