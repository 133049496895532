















import Vue from 'vue';
// import bus from '@/services/bus';

export default Vue.extend({
    name: 'Card',

    props: ['title', 'inprogress', 'refresh', 'onRefresh'],

    methods: {
        refreshPageBookings() {
            // bus.$emit('force-refresh-bookings-page');
            this.onRefresh && this.onRefresh();
        },
    },
});
