


































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import dayjs from 'dayjs';
import Card from '@/components/Card.vue';
import PaymentForm from '@/components/PaymentForm.vue';
import UserDetailsForm from '@/components/UserDetailsForm.vue';
import RideDetailsForm from '@/components/RideDetailsForm.vue';
import ValidityTimer from '@/components/ValidityTimer.vue';
import Tag from '@/components/Tag.vue';
import Map from '@/components/Map.vue';
import mytako, { CreateQuoteRequestRequest, CreateBookingResponse, GetQuotesResponse, Quote, Location } from '@/services/mytako';
import format from '@/services/format';
import log from '@/services/log';
import segment from '@/services/segment';
import store from '@/services/store';
import i18n from '@/services/i18n';

export default Vue.extend({
    metaInfo: {
        title: 'Confirm & Pay',
    },

    components: { Card, Map, ValidityTimer, Tag, PaymentForm, UserDetailsForm, RideDetailsForm },

    data() {
        return {
            // input
            quote: {
                connector: '',
                ref: { quoteId: '' },
                price: { amount: 0, currency: 'EUR', type: 'OTHER' },
                vehicle: { eta: -1 },
                fleet: { name: '', termsAndConditionsUrl: '' },
            },
            quoteRequest: {
                pickupLocation: { displayAddress: '', country: '', latitude: 0, longitude: 0 } as Location,
                destinationLocation: { displayAddress: '', country: '', latitude: 0, longitude: 0 } as Location,
                passengerCount: 0,
                luggageCount: 0,
                pickupAt: '',
            },
            quoteRequestResponse: {
                validity: 0,
                uuid: '',
            },
            bookingCreateResponse: undefined as CreateBookingResponse | undefined,
            deadline: undefined as number | undefined,
            language: i18n.language,

            // form: payment
            showDialogTermsAndConditions: false,

            // misc
            showDialogExpired: false,
        };
    },

    async mounted() {
        log.warn('***** PAGE-3');
        this.$vuetify.goTo(0);

        // handle cases when getting to this page ... but there is no active quote
        this.quote = store.quote.get() as Quote;
        if (this.quote == null) {
            log.debug(`quote #${this.quote} undefined; redirect to home`);
            this.$router.replace('/');
            return;
        }

        // load page-2 data from storage
        this.quoteRequest = store.quoteRequest.get() as Required<CreateQuoteRequestRequest>;
        this.quoteRequestResponse = store.quoteRequestResponse.get() as Required<GetQuotesResponse>;

        // handle cases when getting to this page ... but there is already a matching booking
        this.bookingCreateResponse = store.booking.get() as CreateBookingResponse;
        log.debug(`XX bookingCreateResponse`, this.bookingCreateResponse);
        if (this.bookingCreateResponse != null && this.bookingCreateResponse.uuid != null && this.bookingCreateResponse.status !== 'CREATED') {
            log.debug(`XX bookingCreateResponse => redirect`, this.bookingCreateResponse);
            this.$router.replace(`/bookings/${this.bookingCreateResponse.uuid}`);
        }

        // handle remaining validity timeout from previous page
        const quoteValidityDeadline = store.quoteValidityDeadline.get() as number;
        const validityTimeout = Math.max(quoteValidityDeadline - dayjs().unix(), 0);
        this.deadline = quoteValidityDeadline;
        segment.page('booking', { timerStatus: validityTimeout / 1000 });
        log.debug(`Quote will be valid for ${validityTimeout} seconds until ${dayjs.unix(quoteValidityDeadline)}`);
        setTimeout(() => {
            this.showDialogExpired = true;
            segment.track('booking/quote/expired');
            store.quoteValidityDeadline.clear();
        }, validityTimeout * 1000);
    },

    mixins: [{ methods: { ...format } }],

    methods: {
        areFormsValid() {
            log.debug('forms[].isValid');
            return !!((this.$refs.userDetailsForm as any)?.isValid() && (this.$refs.rideDetailsForm as any)?.isValid());
        },

        refreshOffers() {
            this.$router.replace('/result');
        },

        // form: payment

        async onPaymentFormSubmit() {
            // create a booking slot
            log.debug('onPaymentFormSubmit');
            this.bookingCreateResponse = await mytako.api.bookings.create({
                requestUuid: this.quoteRequestResponse.uuid,
                connector: this.quote.connector,
                connectorOfferId: this.quote.ref.quoteId,
                user: (this.$refs.userDetailsForm as any)?.getValues(),
                details: (this.$refs.rideDetailsForm as any)?.getValues(),
                quote: {
                    currency: this.quote.price.currency,
                    amount: this.quote.price.amount,
                    type: this.quote.price.type,
                },
            });
            store.booking.set(this.bookingCreateResponse);
            log.debug(`new booking created:`, this.bookingCreateResponse);
            if (this.bookingCreateResponse.status !== 'CREATED') {
                this.$router.replace(`/bookings/${this.bookingCreateResponse.uuid}`);
            }
        },

        async onPaymentFormReturning() {
            log.debug('onPaymentFormReturning');
            log.debug(`XX showDialogExpired=${this.showDialogExpired}`);
            if (this.showDialogExpired) {
                return;
            }
            this.bookingCreateResponse = store.booking.get() as CreateBookingResponse;
            log.debug(`XX bookingCreateResponse=${this.bookingCreateResponse}`);
        },

        async onPaymentFormSuccess() {
            log.debug('onPaymentFormSuccess');
            if (this.showDialogExpired) {
                return;
            }
            const bookingCreateResponse = this.bookingCreateResponse;
            log.debug(`bookingCreateResponse: `, bookingCreateResponse);
            if (bookingCreateResponse?.uuid == null) {
                log.debug(`trying to link booking to payment ... but no booking was created before!`);
                // TODO FAIL!
                return;
            }
            const bookingLinkResponse = await mytako.api.bookings.link({ uuid: bookingCreateResponse.uuid });
            store.booking.set(bookingLinkResponse); // TODO rename
            log.debug(`bookingLinkResponse => redirect`, bookingLinkResponse);
            this.$router.replace(`/bookings/${bookingCreateResponse.uuid}`);
        },

        async onPaymentFormAbandon() {
            this.refreshOffers();
        },
    },
});
