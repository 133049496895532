const ipCountry = async (): Promise<string> => {
    const response = await fetch('https://ip2c.org/s');
    if (response.status !== 200) {
        return 'nl';
    }
    const ip2c = await response.text();
    // console.warn('ip2c', ip2c);
    const [, cc2] = ip2c.split(';');
    return cc2;
};

export default ipCountry;
