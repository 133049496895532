




















































































































































































































import Vue from 'vue';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import format from '@/services/format';
import log from '@/services/log';
import mytako, { BackendError, ConfigResponse } from '@/services/mytako';
import segment from '@/services/segment';
import i18n from '@/services/i18n';

export default Vue.extend({
    name: 'PaymentForm',

    props: ['submitEnabled', 'onSubmit', 'onReturning', 'onSuccess', 'onAbandon', 'production', 'amount', 'currency', 'reference'],

    data() {
        return {
            showForm: true,
            showDialogError: false,
            showDialogErrorPayment: false,
            showDialogProcessing: false,
            showSucceededPaymentInfo: false,

            enableFormSubmitButton: true,
            dialogErrorPaymentMessage: '',
            dialogErrorBackendMessage: '',
            dialogProcessingMessage: '',

            config: {} as ConfigResponse,
            shopperReference: undefined as string | undefined,
            adyenConfiguration: undefined as any,
            adyenDropin: undefined as any,
            adyenCheckout: undefined as AdyenCheckout | undefined,
            language: i18n.language,
        };
    },

    async mounted() {
        // handle case where re-entering along 3DSv1 payment flow
        await this.handleReturnFromPayment();

        // pre compute
        log.debug(`isValid XX submitEnabled=${this.submitEnabled}`);
        this.config = await mytako.api.config.env();
        this.shopperReference = `${this.config.ADYEN_MERCHANT_ACCOUNT} R${this.reference.slice(0, 8).toUpperCase()}`;

        // mount
        await this.mount();
    },

    watch: {
        language: async function (value) {
            log.debug(`language has changed to ${value}`);
            this.umount();
            this.mount();
        },
    },

    mixins: [{ methods: { ...format } }],

    methods: {
        // TODO: merge all displayXXXX into 1 updateDisplayState(STATE, props: { message }) + switch case

        enablePaymentFormSubmitButton(enable: boolean) {
            this.enableFormSubmitButton = enable;
        },

        displayPaymentForm(show: boolean) {
            this.enablePaymentFormSubmitButton(show);
            this.showDialogProcessing = false;
            this.showForm = show;
        },

        displayPaymentSucceeded(succeeded: boolean) {
            this.displayPaymentForm(!succeeded);
            this.showDialogProcessing = false;
            this.showSucceededPaymentInfo = succeeded;
        },

        displayBackendErrorDialog(message: string) {
            this.displayPaymentSucceeded(false);
            this.showDialogError = true;
            this.dialogErrorBackendMessage = message;
        },

        displayPaymentErrorDialog(message: string) {
            this.displayPaymentSucceeded(false);
            this.showDialogErrorPayment = true;
            this.dialogErrorPaymentMessage = message;
        },

        displayProcessingDialog(message: string) {
            this.displayPaymentSucceeded(false);
            this.displayPaymentForm(false);
            this.showDialogProcessing = true;
            this.dialogProcessingMessage = message;
        },

        //

        async confirmPayment() {
            // this.enablePaymentFormSubmitButton(false);
            try {
                await this.onSubmit();
            } catch (error) {
                this.displayBackendErrorDialog(error instanceof BackendError ? error.message : 'Une erreur inconnue est survenue [1]');
                return;
            }
            // this.adyenDropin.isValid();
            if (this.adyenDropin) {
                this.adyenDropin.submit();
            }
        },

        async handleReturnFromPayment() {
            if (this.$route.query.c == null || this.$route.query.r == null) {
                return;
            }
            const resultCode = this.$route.query.c;
            const refusalReason = this.$route.query.r;
            if (resultCode == null || refusalReason == null || Array.isArray(resultCode) || Array.isArray(refusalReason)) {
                return; // TODO ? something else than return is needed here!
            }
            segment.track('payment-form/flow/3DSv1-query', this.$route.query);
            await this.onReturning();
            await this.handlePaymentResponse(resultCode, refusalReason);
        },

        async finalizePayment() {
            try {
                this.enablePaymentFormSubmitButton(false);
                await this.onSuccess();
            } catch (error: any) {
                segment.track('booking/error', { errorMessage: error.message, resultCode: error.status });
                this.displayBackendErrorDialog(error instanceof BackendError ? error.message : 'Une erreur inconnue est survenue [2]');
            }
        },

        //

        async handlePaymentResponse(resultCode: string, refusalReason: string) {
            log.debug(`[dropin] -> handlePaymentResponse -> process [${resultCode}]`);
            segment.track('payment-form/summary', {
                amount: this.amount / 100,
                currency: this.currency,
                // timerStatus: this.deadline ? dayjs.unix(this.deadline).diff(dayjs()) / 1000 : null,
                // ...(this.transportNumber ? { [this.transportType]: this.transportNumber } : undefined),
                // ...(this.comments ? { comments: this.comments } : undefined),
                resultCode,
            });
            switch (resultCode) {
                case 'Authorised': {
                    segment.track('payment-form/authorized');
                    this.displayPaymentSucceeded(true);
                    this.umount();
                    this.finalizePayment();
                    break;
                }

                case 'Error':
                    segment.track('payment-form/error', { refusalReason });
                // fall-through

                // eslint-disable-next-line no-fallthrough
                case 'Refused': {
                    segment.track('payment-form/refused', { refusalReason });
                    this.displayPaymentErrorDialog(refusalReason);
                    if (this.adyenCheckout) {
                        this.adyenCheckout.update();
                    } else {
                        this.mount();
                    }
                    break;
                }

                case 'IdentifyShopper':
                case 'RedirectShopper':
                case 'Pending':
                case 'Received':
                default:
                // nop
            }
        },

        async handleBackendResponse(response: any, component: any) {
            log.debug(`[dropin] -> handleBackendResponse ${'*'}`);
            // XX if (!this.areAllFormsValid()) {
            //     return;
            // }
            if (response.action) {
                log.debug(`[dropin] -> handleBackendResponse -> action -> ${'*'}`);
                // this.displayPaymentForm(true); // in case 3DSv2 is required, so free up access to dropin
                this.showDialogProcessing = false;
                component.handleAction(response.action);
            } else {
                await this.handlePaymentResponse(response.resultCode, response.refusalReason);
            }
        },

        async onAdyenDropinSubmit(state: any, component: any) {
            log.debug('[dropin] -> onSubmit', state);
            if (!state.isValid) {
                return;
            }
            this.displayProcessingDialog('SHARED.PAYMENT_FORM.DIALOG_PROCESSING_PAYMENT');
            let response: any;
            try {
                response = await mytako.api.payments.payments({
                    amount: { currency: this.currency, value: this.amount },
                    reference: this.reference,
                    shopperReference: this.shopperReference,
                    // shopperEmail: this.shopperEmail,
                    ...state.data,
                });
            } catch (error: any) {
                this.displayBackendErrorDialog(error.message);
                if (this.adyenCheckout) {
                    this.adyenCheckout.update();
                } else {
                    this.mount();
                }
                return;
            }
            this.handleBackendResponse(response, component);
            switch (response.resultCode) {
                case null:
                    return;
                case 'IdentifyShopper':
                    segment.track('payment-form/flow/3DSv2');
                    break;
                case 'RedirectShopper':
                    segment.track('payment-form/flow/3DSv1');
                    break;
                default:
                    segment.track('payment-form/flow/no3DS');
                    break;
            }
        },

        async onAdyenDropinAdditionalInformation(state: any, component: any) {
            log.debug('[dropin] -> onAdditionalDetails', state);
            this.displayProcessingDialog('SHARED.PAYMENT_FORM.DIALOG_PROCESSING_PAYMENT');
            let response: any;
            try {
                response = await mytako.api.payments.getPaymentsDetails(state.data);
            } catch (error: any) {
                this.displayBackendErrorDialog(error.message);
                if (this.adyenCheckout) {
                    this.adyenCheckout.update();
                } else {
                    this.mount();
                }
            }
            this.handleBackendResponse(response, component);
        },

        async onAdyenDropinError(error: any) {
            log.error('[dropin] -> onError', error);
            this.displayPaymentForm(true);
        },

        async buildAdyenConfiguration() {
            const amount = { currency: this.currency, value: this.amount };
            let response: any;
            try {
                response = await mytako.api.payments.getPaymentMethods({ amount });
            } catch (error: any) {
                this.displayBackendErrorDialog(error.message);
                return;
            }
            return {
                environment: this.config.NODE_ENV === 'production' ? 'live' : 'test',
                clientKey: this.config.ADYEN_CLIENT_KEY as string,
                paymentMethodsResponse: response,
                locale: this.language,
                allowedPaymentMethods: ['scheme'],
                removePaymentMethods: ['bankTransfer_IBAN'],
                reference: this.reference,
                shopperStatement: this.shopperReference,
                amount,
                showPayButton: false,
                paymentMethodsConfiguration: {
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                        // enableStoreDetails: true,
                        name: this.$t('ADYEN.PAYMENT_TITLE_CARD') as string,
                        amount, // check again if needed
                    },
                },
                onSubmit: this.onAdyenDropinSubmit,
                onAdditionalDetails: this.onAdyenDropinAdditionalInformation,
                onError: this.onAdyenDropinError,
            };
        },

        async mount() {
            this.adyenConfiguration = await this.buildAdyenConfiguration();
            log.debug('Adyen Configuration', this.adyenConfiguration);
            this.adyenCheckout = new AdyenCheckout(this.adyenConfiguration);
            this.adyenDropin = this.adyenCheckout.create('dropin');
            this.adyenDropin.mount('#dropin-container');
        },

        async umount() {
            if (this.adyenDropin) {
                this.adyenDropin.unmount();
            }
        },
    },
});
