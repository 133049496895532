
































































































































import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Vue from 'vue';
// @ts-ignore
import VuePhoneNumberInput from 'vue-phone-number-input';
import tlds from '@/services/tlds';
import format from '@/services/format';
import log from '@/services/log';
import { CreateBookingUserDetails } from '@/services/mytako';
import store from '@/services/store';
import i18n from '@/services/i18n';
import ipCountry from '@/services/ip-country';

export default Vue.extend({
    name: 'UserDetailsForm',

    components: { VuePhoneNumberInput },

    data() {
        return {
            userDetails: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                props: {
                    language: '',
                },
            },
            userDetailsPhoneNumber: '',
            firstNameRules: [
                (v: string) => {
                    return !!v?.match(/^[^!@#$%^&*~()_+=\\|/<>\d]{1,70}$/) || this.$t('PAGE3.FORM_RULES.CORRECT_NAME');
                },
            ],
            lastNameRules: [
                (v: string) => {
                    return !!v?.match(/^[^!@#$%^&*~()_+=\\|/<>\d]{1,70}$/) || this.$t('PAGE3.FORM_RULES.CORRECT_NAME');
                },
            ],
            emailRules: [
                (v: string) => {
                    return (
                        !!v?.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        ) || this.$t('PAGE3.FORM_RULES.CORRECT_EMAIL')
                    );
                },
                (v: string) => {
                    const segments = v?.split('.');
                    const last = segments[segments.length - 1];
                    return tlds.isValid(last) || this.$t('PAGE3.FORM_RULES.CORRECT_EMAIL');
                },
            ],
            mobileNumberRules: [
                (v: string) => {
                    return !!v?.match(/^\+\d{1,3}[ -]?\d{1,3}[ -]?\d{1,3}[ -]?\d{1,}$/) || this.$t('PAGE3.FORM_RULES.CORRECT_PHONE_NUMBER');
                },
            ],
            countriesList: ['FR', 'BE', 'DE'],
            defaultCountryCode: 'FR',
            validPhoneNumber: false,
            language: i18n.language,
        };
    },

    async mounted() {
        try {
            const userDetails = store.userDetails.get() ?? { firstName: '', lastName: '', email: '', phoneNumber: '', props: { language: '' } };
            this.saveUserDetails(userDetails);
        } catch {
            // nop
        }
    },

    created() {
        ipCountry().then((cc) => {
            this.defaultCountryCode = cc;
        });
    },

    watch: {},

    mixins: [{ methods: { ...format } }],

    methods: {
        onPhoneNumberUpdate(payload: any) {
            this.validPhoneNumber = payload.isValid === true;
            this.userDetails.phoneNumber = payload.formattedNumber as string;
        },

        saveUserDetails(userDetails: CreateBookingUserDetails) {
            store.userDetails.set(userDetails);
            this.userDetails = userDetails;
            this.userDetailsPhoneNumber = userDetails.phoneNumber;
        },

        storeUserDetails() {
            if (!this.isValid()) {
                return;
            }
            this.saveUserDetails({
                firstName: this.userDetails.firstName,
                lastName: this.userDetails.lastName,
                email: this.userDetails.email,
                phoneNumber: this.userDetails.phoneNumber,
                props: {
                    language: i18n.language?.toLowerCase() ?? 'fr',
                },
            });
        },

        // public

        isValid() {
            const xx = this.validPhoneNumber && (this.$refs.formUserDetails as any).validate();
            log.debug(`UserDetailsForm.isValid => ${xx}`);
            return xx;
        },

        getValues() {
            return this.userDetails;
        },
    },
});
